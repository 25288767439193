<template>
    <div>
        <div class="loading-spinner" v-if="show">
            <div class="loading-spinner-div" style="margin-top:auto;height:100%">
                <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="60" :color="$root.colorLoading" />
            </div>
        </div>
        <b-card>
            <h5 class="fontWeightBold mb-4">پروفایل</h5>
            <div v-if="profile">
                <form @submit.prevent="itemEdited" id="edit-item">
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group>
                                <label for="username"> نام کاربری <i class="fas fa-asterisk text-danger"
                                        style="font-size: 0.5rem;"></i></label>
                                <b-form-input id="username" name="username" v-model="profile.username" data-required>
                                </b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group>
                                <label for="password"> رمز عبور <i class="fas fa-asterisk text-danger"
                                        style="font-size: 0.5rem;"></i></label>
                                <b-form-input id="password" name="password" type="password" data-required>
                                </b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group>
                                <label for="province"> استان</label>
                                <multiselect v-model="selected_province" open-direction="bottom" :options="provinces"
                                    label="name" track-by="id" :searchable="true" :close-on-select="true"
                                    :show-labels="false" @input="loadCities" :disabled="disabled">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group>
                                <label for="city"> شهر <i class="fas fa-asterisk text-danger"
                                        style="font-size: 0.5rem;"></i></label>
                                <multiselect v-model="selected_city" open-direction="bottom" :options="cities"
                                    label="name" track-by="id" :searchable="true" :close-on-select="true"
                                    :show-labels="false" :disabled="disabled">
                                </multiselect>
                                <input type="hidden" name="city_id"
                                    :value="selected_city != null ? selected_city.id : ''">
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group>
                                <label for="category"> دسته بندی <i class="fas fa-asterisk text-danger"
                                        style="font-size: 0.5rem;"></i></label>
                                <multiselect open-direction="bottom" v-model="selected_categories" :options="categories"
                                    label="name" track-by="id" :multiple="true" :searchable="true" :show-labels="false">
                                </multiselect>
                                <div v-for="item in selected_categories" :key="item.id">
                                    <input type="hidden" name="categories[]" :value="item.id">
                                </div>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group>
                                <label for="status"> تصویر جواز </label>
                                <b-form-file name="license_image" accept="image/*" v-model="file"
                                    @change="addImages($event)"></b-form-file>
                            </b-form-group>
                        </div>
                    </div>
                    <div v-if="myImage">
                        <a :href="myImage" class="rounded">
                            <img :src="myImage" style="width: 200px; height: 200px;">
                        </a>
                    </div>
                    <div class="clearfix mt-2">
                        <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
                    </div>
                    <input type="hidden" value="POST" name="_method">
                </form>
            </div>

        </b-card>
    </div>
</template>
<script>
    import mixins from './mixins/mixins'
    export default {
        mixins: [mixins],
        data() {
            return {
                title: 'پروفایل',
                url: '/api/seller/profile',
                profile: {},
                selected_categories: null,
                categories: [],
                provinces: [],
                selected_city: null,
                selected_province: null,
                cities: [],
                file:[],
                myImage:[],
            }
        },
        mounted() {
            this.show = true
            this.$axios.get(this.$root.baseUrl + this.url)
                .then(response => {
                    this.show = false
                    this.profile = response.data.data
                    this.loadProvinces()
                    this.loadCategories()
                    this.selected_city = this.profile.city
                    this.selected_categories = this.profile.categories
                    this.myImage = this.profile.license_image ? this.profile.license_image.url : []
                })

        },
        methods: {
            loadCities(item) {
                this.cities = item.cities
            },
            loadProvinces() {
                this.$axios.get(this.$root.baseUrl + '/api/provinces/' + 102)
                    .then(response => {
                        this.provinces = response.data.data
                        this.selected_province = this.profile.city ? this.provinces.filter(item => item.id == this
                            .profile.city.province_id)[0] : ''
                    })
            },
            loadCategories() {
                this.$axios.get(this.$root.baseUrl + `/api/list/categories`)
                    .then(response => {
                        this.categories = response.data.data
                    })
            },
            itemEdited() {
                this.disabled = true
                let form = document.getElementById('edit-item')
                let formData = new FormData(form)

                this.$axios.post(this.$root.baseUrl + this.url, formData)
                    .then(response => {
                        this.$root.success_notification(response.data.message)
                        this.$root.$set(this.profile, response.data.data)
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            addImages(event) {
                const data = URL.createObjectURL(event.target.files[0]);
                this.myImage = data;
            },
            removeImage() {
                this.file = [];
                this.myImage = [];
            },
        }
    }
</script>